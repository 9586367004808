@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.root {
  :global {
    .header {
      border-bottom: 1px solid $bg-gray;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100;
      background-color: $bg-white;
      max-height: 60px;
      .header-content {
        height: 100%;
      }
      .heading-title {
        font-size: 17px;
        font-weight: 700;
        color: $text-dark;
      }
      .ant-space-item {
        font-size: 16px !important;
        max-width: 250px;
        .icon-arrow-down::before {
          color: $text-dark !important;
        }
        .ant-typography {
          font-size: 16px !important;
          font-weight: 400 !important;
          margin: 0;
        }
      }
    }

    .ant-layout-sider {
      flex: 0 0 275px !important;
      max-width: 275px !important;
      min-width: 275px !important;
      width: 275px !important;
      background-color: #ffffff;
      &.ant-layout-sider-collapsed {
        flex: 0 0 80px !important;
        max-width: 80px !important;
        min-width: 80px !important;
        width: 80px !important;
        .ant-layout-sider-children .menu-contact {
          display: none;
        }
      }
      &-children {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    .ant-menu-item {
      padding-left: 34px !important;
      &-selected {
        display: flex;
        align-items: center;
        .ant-menu-title-content {
          font-weight: 700;
        }
        .ant-menu-item-icon {
          color: #fff !important;
        }
      }
    }
    .ant-menu-title-content {
      font-weight: 400;
      margin-left: 17px !important;
    }
    .ant-layout-header {
      line-height: unset !important;
      padding: 10px 34px !important;
    }
    .ant-space-item {
      font-size: 16px !important;
    }

    .heading-content {
      max-height: 78px;
      background-color: $bg-white;
      padding: 20px;
      border-radius: 20px;
      display: block;
    }
    .ant-menu-inline {
      border-right: unset !important;
    }
    .navigate_account_info li {
      background-color: $bg-white !important;
      color: $text-dark;
      span {
        font-weight: 400 !important;
      }
      &:hover {
        color: $text-dark !important;
      }
    }

    @media (max-width: 600px) {
      .ant-layout-header {
        line-height: unset !important;
        padding: 10px 14px !important;
      }
      .ant-space {
        gap: 0 !important;
        &-item {
          font-size: 14px !important;
        }
      }
    }
  }
}

.sider {
  overflow: auto;
  position: fixed !important;
  left: 0;
  top: 60px;
  bottom: 0;
  background: $bg-white;
  width: max-content;
  :global {
    .menu-contact {
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.14);
      padding: 18px 13px;
      font-family: "Hiragino Sans" !important;
    }
  }
}

.content {
  padding: 90px 15px 40px;
  overflow-y: auto;
  overflow-x: scroll;
  transition: all 0.1s ease-in;
  margin-left: 275px;
  min-height: 100vh !important;

  &.contentCollapsed {
    margin-left: 80px;
  }
}

:global {
  .ant-dropdown {
    min-width: 0 !important;
  }
  .ant-dropdown-menu {
    max-width: 240px;
    margin-top: 15px !important;
    padding: 8px 0 !important;
    border-radius: 0 !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    &-item:hover {
      background-color: $bg-main;
    }
  }
  .ant-dropdown-menu-item-divider {
    background-color: $border-main !important;
  }
  .ant-dropdown-menu-item {
    padding: 12px 24px !important;
    .ant-dropdown-menu-title-content {
      line-height: 150% !important; // TODO: line-height common setting 後に削除?
    }
    &.ant-dropdown-menu-item-disabled {
      cursor: default !important;
      .ant-dropdown-menu-title-content {
        span {
          display: block;
          &.secondary-title {
            color: #9e9e9e !important;
          }
        }
      }
    }
    .ant-dropdown-menu-title-content {
      color: $text-dark !important;
      font-size: 16px !important;
    }
  }
}
