.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.isFullscreen {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1000;
    background-color: rgba(5, 5, 5, 0.2);
    height: -webkit-fill-available;
    height: -moz-available;
    height: 100vh;
    height: stretch;
  }
}
