@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/common.module.scss";
@import "@app/styles/icons.module.scss";

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: auto;
  word-break: break-word;
  font-family: "Noto Sans JP", sans-serif !important;
  & > iframe {
    display: none;
  }
  margin: 0 !important;
  letter-spacing: 0.01em;
}

input:-webkit-autofill,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
}

/* Force ant input number to take up all width when used inside col */
.ant-col {
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
  .ant-input {
    font-weight: 500;
  }
}
.ant-modal-wrap {
  .ant-modal {
    top: 30% !important;
    right: 5%;
  }
}

// Fix for blurry images on retina https://stackoverflow.com/questions/13347947/css-filter-on-retina-display-fuzzy-images
img {
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}

// Menu
.ant-menu-item-selected {
  &:hover {
    color: $bg-white !important;
  }
}

.ant-menu-item {
  width: 90% !important;
  font-weight: 700;
  height: 55px !important;
  border-radius: 0 50px 50px 0 !important;
  &::after {
    display: none;
  }
}

// Layout
.ant-layout {
  background-color: unset;
}

// Button
.ant-btn {
  height: 46px;
  font-weight: 500;
  &.ant-btn-primary:not([disabled]):not(.ant-btn-disabled) {
    box-shadow: 0px 8px 12px rgba(0, 128, 128, 0.18);
  }
  &:disabled {
    border-color: $text-disabled !important;
  }
  &.download-button {
    box-shadow: none;
    &:hover {
      color: $primary-main !important;
      opacity: 0.6;
    }
    height: 36px;
    padding-inline-start: $spacing-5;
    padding-inline-end: $spacing-6;
  }
  // TODO: figmaのLMSは全てantd button のmiddleで実装されている
  // antd の lg はログイン系の画面、smは openNotification で利用しているが、
  // 使用頻度的に、今mdで実装されているものをsizeで制御するようにしたほうがいい
  &.ant-btn-round {
    padding-inline-start: $spacing-6;
    padding-inline-end: $spacing-6;
    &:has(.ant-btn-icon) {
      padding-inline-start: $spacing-5;
    }
  }
  &.large-button {
    font-weight: $font-weight-400;
    font-size: $font-size-18;
    height: 48px;
  }
  &.middle-actions {
    font-weight: $font-weight-400;
    font-size: $font-size-14; // 16
    height: 36px;
  }
  &.small-actions {
    font-weight: $font-weight-400;
    font-size: $font-size-14;
    height: 32px;
  }
}
.ant-btn-lg {
  font-weight: $font-weight-700;
  max-width: 292px;
}

//Input
.ant-input-suffix {
  color: currentColor !important;
}
.ant-input-password-icon {
  color: currentColor !important;
  &.anticon {
    transform: scaleX(-1);
    &:hover {
      color: #3fbbff;
    }
    max-width: 18px;
    max-height: 15.67px;
  }
}
.ant-input-affix-wrapper > .ant-input:not(textarea) {
  font-size: 17px;
}
.ant-input-prefix {
  margin-right: 12px !important;
  .icon-email {
    font-size: 15px;
    color: #696969;
  }
}
.icon-lock {
  font-size: $font-size-20;
}

.ant-input-suffix > :not(:last-child) {
  margin-right: 0px !important;
}

.ant-btn-sm {
  * {
    font-size: 14px;
  }
}

.ant-form-item-extra {
  font-size: 10px !important;
  line-height: 14.48px !important;
  font-weight: 500;
  margin-top: 12px;
}

.ant-btn-default:not([disabled]) {
  &:hover {
    color: #ffffff;
  }
}

//Select
.ant-select-clear {
  right: 18px !important;
}
.ant-select-dropdown {
  border-radius: 0 0 9px 9px;
  border-bottom: 1px solid #bdbdbd;
  z-index: 10 !important;
  padding: 0 !important;
  box-shadow: none !important;
  top: 0;
}
.ant-select-item {
  padding: 8px 12px !important;
  &.ant-select-item-option-grouped {
    padding: 8px 12px 8px 24px !important;
  }
  border-radius: 0 !important;
  height: 32px;
  min-height: 32px !important;
}
.ant-select-open {
  .ant-select-selector {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 7px 12px !important;
}
.ant-select-selection-item {
  display: flex;
  align-items: center;
  font-weight: 500 !important;
  display: inline-block;
  line-height: unset !important;
}

.ant-select-item-option-content,
.ant-select-selection-search {
  font-size: 12px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none !important;
}
.ant-select-arrow {
  right: 16px !important;
}
.rc-virtual-list-holder-inner {
  border: 1px solid #bdbdbd;
  border-top: 1px solid #d9d9d9;
}
.ant-select-item-option {
  border-bottom: 1px solid #d9d9d9;
}
.ant-select-selection-placeholder {
  line-height: unset !important;
}

//Radio
.ant-radio-checked .ant-radio-inner {
  background-color: #fff !important;
}

.ant-form-item-explain {
  margin: 6px 0;
}
.ant-form-item-explain-error {
  color: #ff0000 !important;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
}

.ant-form-item-feedback-icon-error,
.ant-form-item-feedback-icon-success {
  display: none;
}

//Collapse
.ant-collapse-item-active {
  .ant-collapse-header {
    border-bottom: 1px solid #d9d9d9 !important;
    padding-bottom: 17px !important;
  }
}
.ant-collapse-content-box {
  padding: 0 !important;
}
.ant-collapse-header {
  font-size: 16px;
  font-weight: 500;
  padding: 0 !important;
}
.ant-collapse-item {
  border: none !important;
}
.ant-card-body {
  padding: 18px !important;
}
.is-printing {
  background-color: $bg-white;
  .no-print {
    display: none;
  }
}

// fix safari
#root {
  .is-printing {
    canvas {
      position: relative;
    }
    .ant-spin-nested-loading .ant-spin-container::after {
      display: none;
    }
  }
}
