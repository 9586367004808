@import "@app/styles/colors";
@import "@app/styles/variables";
@import "@app/styles/mixins";

.menu_contact {
  :global {
    padding: 18px 20px;
    width: 247px;
    min-height: 110px;
    font-family: "Hiragino Sans" !important;
    border-radius: 14px;
    @include flex-align-center-column;
    font-weight: $font-weight-600 !important;
    color: $text-white;
    .menu_contact_content_title {
      width: 35%;
    }
    .menu_contact_content_text {
      width: 65%;
    }
    .menu_contact_content_email {
      margin-top: 3px;
    }
    span {
      display: inline-block;
    }
  }
}
