@import "@app/styles/colors";
@import "@app/styles/variables";

@mixin media-query($breakpoint: md, $rule: min, $subtraction: false) {
  $mq-breakpoints: (
    "xs": $screen-xs,
    "sm": $screen-sm,
    "md": $screen-md,
    "lg": $screen-lg,
    "xl": $screen-xl,
    "xxl": $screen-xxl,
  );
  $breakpoint: map_get($mq-breakpoints, $breakpoint);
  @if $rule==max and $subtraction {
    $breakpoint: $breakpoint - 1;
  }
  @media screen and (#{$rule}-width: $breakpoint) {
    @content;
  }
}

@mixin text-truncate($line: 1) {
  display: block;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin flex-align-center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
