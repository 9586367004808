@import "variables";
@import "colors";
@import "@app/styles/mixins";

input::-webkit-credentials-auto-fill-button {
  visibility: hidden !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

input::-ms-reveal,
input::-ms-clear {
  display: none !important;
}

.no-wrap {
  white-space: nowrap;
}

.pre-wrap {
  white-space: pre-wrap;
}

.no-wrap-hidden {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.white-space-pre {
  white-space: pre;
}

// flex
.d-flex {
  display: flex;
}

.flex-align-center {
  display: flex;
  align-items: center !important;
}

.flex-align-end {
  display: flex;
  align-items: flex-end !important;
}

.flex-start {
  display: flex;
  align-items: flex-start !important;
}

.flex-space-between {
  display: flex;
  justify-content: space-between !important;
}

.flex-space-around {
  display: flex;
  justify-content: space-around !important;
}

.flex-space-evenly {
  display: flex;
  justify-content: space-evenly !important;
}

.flex-justify-start {
  display: flex;
  justify-content: flex-start !important;
}

.flex-justify-center {
  display: flex;
  justify-content: center !important;
}

.flex-justify-end {
  display: flex;
  justify-content: flex-end !important;
}

.flex-justify-align-end {
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.flex-direction-column {
  flex-direction: column !important;
}

.flex-direction-column-center {
  display: flex;
  flex-direction: column !important;
  align-items: center;
}

.flex-space-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-none {
  display: none;
}

// Fonts
$font-size-list: 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 26, 27, 32;

@each $size in $font-size-list {
  .font-#{$size} {
    font-size: #{$size}px !important;
  }
}

.font-noto-san {
  font-family: "Noto Sans JP", sans-serif !important;
}

// Text Aligns
.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-end {
  text-align: end !important;
}

.text-left {
  text-align: left !important;
}

// Font weight
.font-weight-regular {
  font-weight: $font-weight-300 !important;
}

.font-weight-normal {
  font-weight: $font-weight-400 !important;
}

.font-weight-bold {
  font-weight: $font-weight-700 !important;
}

.font-weight-medium {
  font-weight: $font-weight-500 !important;
}

//border-radius
.border-radius-20 {
  border-radius: 20px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-inner {
  border-radius: $radius-size-inner;
}

.border-radius-outer {
  border-radius: $radius-size-outer;
}

//Borders
.no-border {
  border: none !important;
}
.border-bottom-color {
  border-bottom: 1px solid #3fbbff;
}
.border-bottom-account-color {
  border-bottom: 1px solid #d9d9d9;
}
.border-normal {
  border: 1px solid $border-gray;
}

// Spaces
$spaces: (
  "0": $spacing-0,
  "1": $spacing-1,
  "2": $spacing-2,
  "3": $spacing-3,
  "4": $spacing-4,
  "5": $spacing-5,
  "6": $spacing-6,
  "7": $spacing-7,
  "8": $spacing-8,
  "9": $spacing-9,
  "10": $spacing-10,
  "auto": "auto",
);

$gap-list: 4, 5, 6, 8, 10, 12, 14, 16, 18, 19, 20, 24, 32, 46;

@each $gap in $gap-list {
  .gap-#{$gap} {
    gap: #{$gap}px !important;
  }
}

// line height
$line-height-list: 3, 18, 20, 21, 22, 24, 32;
@each $height in $line-height-list {
  .line-height-#{$height} {
    line-height: #{$height}px !important;
  }
}

// Margins
@each $key, $value in $spaces {
  .m-#{$key} {
    margin: #{$value} !important;
  }

  .mt-#{$key} {
    margin-top: #{$value} !important;
  }

  .mr-#{$key} {
    margin-right: #{$value} !important;
  }

  .mb-#{$key} {
    margin-bottom: #{$value} !important;
  }

  .ml-#{$key} {
    margin-left: #{$value} !important;
  }

  .my-#{$key} {
    margin-top: #{$value} !important;
    margin-bottom: #{$value} !important;
  }

  .mx-#{$key} {
    margin-left: #{$value} !important;
    margin-right: #{$value} !important;
  }
}

// Paddings
@each $key, $value in $spaces {
  .p-#{$key} {
    padding: #{$value} !important;
  }

  .pt-#{$key} {
    padding-top: #{$value} !important;
  }

  .pr-#{$key} {
    padding-right: #{$value} !important;
  }

  .pb-#{$key} {
    padding-bottom: #{$value} !important;
  }

  .pl-#{$key} {
    padding-left: #{$value} !important;
  }

  .py-#{$key} {
    padding-top: #{$value} !important;
    padding-bottom: #{$value} !important;
  }

  .px-#{$key} {
    padding-left: #{$value} !important;
    padding-right: #{$value} !important;
  }
}

/* Colors */
$colors: (
  "white": $text-white,
  "black": black,
  "dark": $text-dark,
  "primary": $bg-primary,
  "orange": $bg-orange,
  "orange-light": $bg-orange-light,
  "purple-dark": $bg-purple-dark,
  "purple": $bg-purple,
  "purple-light": $bg-purple-light,
  "link": $text-link,
);

// Text colors
@each $key, $value in $colors {
  .text-#{$key} {
    color: #{$value} !important;
  }
}

// Bg colors
@each $key, $value in $colors {
  .bg-#{$key} {
    background-color: #{$value} !important;
  }
}

// Cursor
.cursor-pointer {
  cursor: pointer !important;
}

/* Positions */
$positions: "static", "relative", "absolute", "fixed", "sticky";

@each $position in $positions {
  .position-#{$position} {
    position: #{to-lower-case(#{$position})} !important;
  }
}

.position-center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// text-decoration
.text-underline {
  text-decoration: underline !important;
}
.text-underline-skip-ink {
  text-decoration: underline !important;
  text-decoration-skip-ink: none;
}

//width
.width-fit-content {
  width: fit-content !important;
}

.full-width {
  width: 100% !important;
}

//border
.border-solid {
  border: 1px solid;
  padding: 0 8px;
}

//text-overflow
.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
}

// float
.float-left {
  float: left;
}
.float-right {
  float: right;
}

// Text break word
.break-word {
  overflow-wrap: break-word;
}

//link
.text-link {
  text-decoration: underline !important;
  text-underline-offset: 2px;
  text-decoration-skip-ink: none;
}

/* corner radius */
.top-right-none {
  border-top-right-radius: 0 !important;
}

.top-left-none {
  border-top-left-radius: 0 !important;
}

.bottom-right-none {
  border-bottom-right-radius: 0 !important;
}

.bottom-right-none {
  border-top-right-radius: 0 !important;
}

.icon-line {
  width: 18px;
  border: 2px solid $bg-orange;
  border-radius: 10px;
  margin: auto;
}

.icon-line-no-round {
  @extend .icon-line;
  border-radius: 0;
}

.icon-rect {
  width: 22px;
  height: 16px;
  background-color: $bg-orange-light;
  margin: auto;
  border-radius: 3px;
}

.summary-card-energy {
  .ant-card {
    &-head {
      min-height: 32px;
      font-size: $font-size-12;
      border-bottom: 1px solid $border-gray;
    }

    &-head,
    &-body {
      padding-left: $spacing-4;
      padding-right: $spacing-4;
    }
  }

  &.ant-card-bordered {
    border: 1px solid $border-gray !important;
  }
}

.custom-echart-btn {
  border-radius: 8px;
  width: 50px !important;
  height: 50px !important;
  border: none;
  // background: $primary-third;
  outline: none;
  box-shadow: none !important;
  font-weight: $font-weight-700;
  font-size: $font-size-16;
}

.absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.text-main {
  color: currentColor !important;
}
