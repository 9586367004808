$text-white: #fff;
$text-disabled: #f5f5f5;
$text-black: #000000;
$text-dark: #333333;
$text-link: #3557c4;
$text-tabs: #666666;

$primary-main: #0089bc;
$primary-second: #1090c0;
$primary-third: #eaf1f2;
$primary-lighter: #eef9ff;

$bg-white: #fff;
$bg-main: #eef9ff;
$bg-gray: #f0f2f5;
$bg-gray-light: #f5f5f5;
$bg-orange: #ff6c00;
$bg-orange-light: #ffa700;
$bg-purple-dark: #302751;
$bg-purple: #694ad1;
$bg-purple-light: #907cd9;
$bg-lavender-dark: #c1b7df;
$bg-lavender: #e5dcff;
$bg-lavender-light: #f9f5ff;
$bg-primary: #0089bc;

$border-main: #bdbdbd;

$bg-input: #e9f0fd;

$border-gray: #d0d9d8;

$bg-table-cell-first-odd: #ffffff;
$bg-table-cell-first-even: #f5f5f5;
$bg-table-cell-second-odd: #fafafa;
$bg-table-cell-second-even: #efefef;
