@font-face {
  font-family: "icomoon";
  src: url("../assets/fonts/icomoon.eot");
  src: url("../assets/fonts/icomoon.eot") format("embedded-opentype"),
    url("../assets/fonts/icomoon.ttf") format("truetype"),
    url("../assets/fonts/icomoon.woff") format("woff"),
    url("../assets/fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  a {
    font-family: var(--font-family);
  }
}

.icon-menu:before {
  content: "\e90f";
}

.icon-question:before {
  content: "\e906";
}

.icon-pen:before {
  content: "\e916";
}

.icon-arrow-left:before {
  content: "\e90c";
}

.icon-arrow-right:before {
  content: "\e907";
}

.icon-email:before {
  content: "\e90a";
}

.icon-lock:before {
  content: "\e909";
  color: #696969;
}

.icon-logo:before {
  content: "\e911";
  color: #42ac8d;
}

.icon-report:before {
  content: "\e912";
}

.icon-search:before {
  content: "\e908";
}

.icon-energy:before {
  content: "\e910";
}

.icon-flag:before {
  content: "\e914";
}

.icon-arrow-down:before {
  content: "\e907";
  // color: #0089bc;
}

.icon-phone:before {
  content: "\e913";
  color: #fff;
}

.icon-user:before {
  content: "\e900";
  color: #696969;
}

.icon-co2:before {
  content: "\e915";
}

// not used
.icon-plus .path1:before {
  content: "\e903";
}
// not used
.icon-plus .path2:before {
  content: "\e904";
  color: #fff;
  margin-left: -1em;
}
// not used
.icon-remove .path1:before {
  content: "\e901";
  color: #f00;
}
// not used
.icon-remove .path2:before {
  content: "\e902";
  color: #fff;
  margin-left: -1em;
}
